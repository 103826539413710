<template>
  <div class="p-10 flex flex-col items-center">
    <!-- Start notification -->
    <successNotification v-if="showSuccessNotification" />
    <failNotification v-if="showFailNotification" />
    <!-- End notification -->

    <div v-if="beneficiaryData" class="overflow-x-hidden">
  <!-- Welcome message -->
  <div class="flex flex-col lg:flex-row gap-2 items-center lg:items-start">
    <h1 class="text-2xl font-bold text-center lg:text-right">مرحبا بكم {{ beneficiaryData.entityName }} في نظام الحجز السريع</h1>
    <img :class="{ 'w-10': beneficiaryData.profilePictureUrl }" :src="beneficiaryData.profilePictureUrl" alt="" class="mt-2 lg:mt-0">
  </div>
  <h2 class="text-[#9E9E9E] text-center lg:text-right">
    هنا يمكنكم الحجز لاجتماعاتكم بسهولة ويسر
  </h2>
  <div class="mt-10">
    <h3 class="text-3xl font-bold mb-2 text-center lg:text-right">حجز اجتماع جديد</h3>
  </div>
  <div class="lg:border lg:rounded-lg flex flex-col items-center p-4">
    <!-- Meeting booking form -->
    <div class="flex flex-col space-y-4 my-16 w-full lg:w-auto">
      <!-- First input pair -->
      <div class="flex flex-col sm:flex-row gap-4 w-full">
        <div class="flex flex-col my-4 w-full">
          <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
          <div class="w-full lg:w-[360px]">
            <select @change="available_hours(); timeRange()" class="inputFild h-11 w-full" name="meetingRoom" id="meetingRoom" v-model="meetingRoom">
              <option v-for="(room, index) in meetingRooms" :key="index" :value="room">
                {{ room }}
              </option>
            </select>
          </div>
          <div v-if="showValidationError">
            <p v-if="meetingRoom == null" class="error">
              يرجى تعبئة الحقل
            </p>
          </div>
        </div>
        <div class="flex flex-col my-4 w-full">
          <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
          <div class="w-full lg:w-auto">
            <input @change="checkUnavailableDates" class="inputFild w-full" type="date" name="meetingDate" id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate" />
          </div>
          <div v-if="showValidationError">
            <p v-if="meetingDate == ''" class="error">
              يرجى تعبئة الحقل
            </p>
          </div>
        </div>
      </div>
      <!-- Second input pair -->
      <div class="flex flex-col sm:flex-row gap-4 w-full">
        <div class="flex flex-col my-2 w-full">
          <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
          <div class="w-full lg:w-[360px]">
            <select :disabled="disableLastInputs" @change="deleteStartTime" class="inputFild h-11 w-full" name="meeting_duration" id="meeting_duration" v-model="meeting_duration">
              <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}</option>
            </select>
          </div>
          <div v-if="showValidationError">
            <p v-if="meeting_duration == ''" class="error">
              يرجى تعبئة الحقل
            </p>
          </div>
        </div>
        <div class="flex flex-col my-2 w-full">
          <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
          <div class="w-full lg:w-[360px]">
            <select @focus="filteredStartTimes" @change="filteredEndTimes" :disabled="disableIfNoDuration" class="inputFild h-11 w-full" name="startTime" id="startTime" v-model="startTime">
              <option v-for="(time, index) in filteredTimes" :key="index" :value="time" style="direction: ltr; text-align: right;">
                {{ time }}
              </option>
            </select>
          </div>
          <div v-if="showValidationError">
            <p v-if="startTime == ''" class="error">
              يرجى تعبئة الحقل
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center gap-4">
      <div>
        <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة {{ this.startTime }} وينتهي الساعة {{ this.endTime }}</p>
      </div>
    </div>
    <div v-if="isLoading" class="flex justify-center items-center my-7">
      <div class="text-center">
        <div role="status" class="flex gap-2">
          <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span class="text-gray-500 font-semibold">جاري الإرسال</span>
        </div>
      </div>
    </div>
    <!-- Start Button -->
    <div class="text-center pb-6 pt-4">
      <button @click="submitData" class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">تأكيد الحجز</button>
    </div>
    <!-- End Button -->
  </div>
</div>



    <!-- WHILE IT LOADING THE USER DATA AND CHECK IF ID EXIST -->
    <div v-if="loading" class="flex items-center justify-center h-64 bg-white shadow-lg rounded-lg p-6 animate-fade-in">
  <div class="flex items-center space-x-4 gap-3">
    <div class="animate-spin rounded-full h-7 w-7 border-4 border-[#00B69B] border-t-transparent"></div>
    <span class="text-[#00B69B] font-semibold text-base">جاري تحميل الصفحة...</span>
  </div>
</div>

<div v-else-if="!beneficiaryData" class="flex items-center justify-center h-64 bg-white shadow-lg rounded-lg p-6">
  <div class="text-center space-y-4">
    <svg class="h-16 w-16 text-red-600 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <p class="text-gray-600 font-medium text-xs leading-relaxed">
      نأسف، لا يوجد في سجلاتنا رقم تعريف مرتبط بهذا الطلب. يرجى الاتصال بفريق خدمة العملاء في شركة quick step
      للمساعدة في تحديد رقم التعريف الصحيح.
    </p>
  </div>
</div>

    <!-- WHILE IT LOADING THE USER DATA AND CHECK IF ID EXIST -->



  </div>
</template>


<script>
import successNotification from "@/components/Notification/successNotification.vue";
import failNotification from "@/components/Notification/failNotification.vue";
import { supabase } from "../../supabase";
import filter from "@/mixins/filter.js";

import { mapMutations } from 'vuex'

export default {
  name: "singleID",
  components: {
    successNotification,
    failNotification,
  },
  data() {
    return {
      identificationNumber: '',
      id: this.$route.params.id, // the id that entered in url
      beneficiaryData: null, // fetched all the data of the beneficiary
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,

      meetingRoom: null, // store the selected room from user
      meetingRooms: [], // fetch all the available and valid halls

      meetingDate: '', // store the selected date from user
      meeting_duration: '', // store the selected duration of meeting from user
      duration: [], // the duration times available

      timeArray: [],
      filteredTimes: [],

      unavailableDates: [],

      startTime: '',

      endTime: '',

      isLoading: false, // icon of loading 

      reservationHolderName: '',
      entityName: '',
      entityType: '',
      phoneNumber: '',

      isDataLoaded: false,

      loading: true


    };
  },
  mixins: [filter],
  methods: {
    // Function to submit meeting data
    async submitData() {
  try {
    // Check if all required fields are filled
    if (this.isDataLoaded && this.entityType && this.entityName && this.meetingRoom && this.meetingDate && this.meeting_duration && this.startTime && this.endTime) {
      console.log('All fields are filled, proceeding with data submission...');

      // Set isLoading to true before starting the data submission process
      this.isLoading = true;

      // Insert a new Meeting record in the 'Meeting' table
      const { data, error } = await supabase
        .from('Meeting')
        .insert([{
          reservationHolderName: this.beneficiaryData.entityName, entityName: this.entityName,
          entityType: this.entityType, phoneNumber: this.phoneNumber, meetingRoom: this.meetingRoom, meetingDate: this.meetingDate,
          meeting_duration: this.meeting_duration, startTime: this.startTime, endTime: this.endTime, meetingStatus: 'معلق'
        },])
        .select();

      console.log(data);
      if (error) {
        console.log('gg', error);
      }

      // Show the success notification after insertion is successful
      this.showSuccessNotification = true;

      // Hide the notification after 3 seconds, set identificationNumber, send notification, and redirect
      setTimeout(() => {
        this.showSuccessNotification = false;
        this.identificationNumber = data[0].identificationNumber;
        this.insertNotification();
        console.log('this.id', this.id);
        this.setId(this.id)
        this.$router.push({ path: '/thankYou' })
      }, 1700);
    } else {
      // Show the fail notification if any field is empty or there are validation errors
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 1700);
    }
  } catch (error) {
    console.error('Error submitting data:', error.message);

    // Show a browser alert with the error message
    window.alert('حدث خطأ في الشبكة. الرجاء التواصل مع المنشأة او المحاولة في وقت لاحق');

    // Set a timeout function to execute after 1700 milliseconds. This function will show the validation error notification.
    setTimeout(() => {
      this.showValidationError = true;
    }, 1700);
  } finally {
    // Set isLoading to false after the data submission process is completed
    this.isLoading = false;
  }
},

    ...mapMutations(['setId']),

    // Function to asynchronously insert a notification into the database
    async insertNotification() {

      // Prepare the notification object
      const notification = {
        by: this.beneficiaryData.entityName, // Get the sender's username from sessionStorage
        notificationType: 'newInMeeting', // Set the notification type to 'newInMeeting'
        identificationNumber: this.identificationNumber, // Include the identification number associated with the meeting
        readed_by: [], // Initialize an empty array for users who have read the notification
      };

      // Send the notification to the Supabase notification table
      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          // Check if there is an error in the result
          if (result.error) {
            console.error('Failed to send notification:', result.error); // Log the error if there is one
          }
        }).catch(error => {
          console.error('Error sending notification:', error); // Log any error that occurred during the process
        });

    },
  },
  computed: {
    // Function to disable inputs based on certain conditions
    disableLastInputs() {
      // Check if all necessary inputs are filled
      if (this.entityType && this.entityName && this.meetingRoom && this.meetingDate) {
        return false; // If all inputs are filled, return false to enable inputs
      } else {
        return true; // If any required input is missing, return true to disable inputs
      }
    },
    // Function to disable inputs if meeting duration is not set
    disableIfNoDuration() {
      // Check if meeting duration is set
      if (this.meeting_duration) {
        return false; // If duration is set, return false to enable inputs
      } else {
        return true; // If duration is not set, return true to disable inputs
      }
    },

  },
  async created() {
    try {
      const { data: Beneficiary, error } = await supabase
        .from("Beneficiary")
        .select("*")
        .eq("identificationNumber", this.id);



      if (error) {
        console.error("Error fetching beneficiary data:", error);
        this.loading = false
      } else if (Beneficiary.length === 0) {
        console.log("No data found for this ID.");
        this.loading = false
      } else {
        this.beneficiaryData = Beneficiary[0];
        this.loading = false

        // Assign values here to ensure they are set after beneficiaryData is assigned
        this.reservationHolderName = this.beneficiaryData.reservationHolderName;
        this.entityName = this.beneficiaryData.entityName;
        this.phoneNumber = this.beneficiaryData.phoneNumber;
        this.entityType = 'داخلي'; // Assuming entityType is a static value
        this.isDataLoaded = true;

      }
    } catch (e) {
      console.error("Unexpected error:", e);
    }
  },

  async mounted() {
    // Retrieve the meeting room names from the 'Hall' table in the database
    let { data: Hall } = await supabase.from("Hall").select("hallName, hallStatus");
    // Add filtered meeting room names to the component's meetingRooms array
    this.meetingRooms.push(
      ...Hall.filter((e) => e.hallStatus === "فعال").map((e) => e.hallName)
    );
  },
};
</script>

<style></style>