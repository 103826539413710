import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import singleID from '@/views/singleID.vue'
import thankYou from '@/views/thankYou.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import externalBooking from '../components/Form/externalBooking.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/newBooking/:id' ,
     component: singleID,
  },
  {
    path: '/externalBooking',
    name: 'externalBooking',
    component: externalBooking,
    // meta: { requiresAuth: true } 
  }, 
  {
    path: '/thankYou' , 
    component: thankYou, 
    props: true
  },
  { 
    path: '*', 
    component: PageNotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
